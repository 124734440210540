<template>
  <div class="wrapper">
    <div class="wrapper-verfication-box">
      <template v-if="$store.state.registerData.registerStatusId == 1">
        <div class="text-center">
          <v-icon large color="white" style="font-size: 48px"
            >mdi-email-outline
          </v-icon>
          <div class="mt-5" style="font-size: 24px">ยืนยันตัวตน</div>
          <div class="white--text mt-6">กรุณากรอกเลข OTP ที่ส่งไปยัง</div>
          <div class="white--text">หมายเลขโทรศัพท์ {{ phoneNumber }}</div>
          <div class="white--text">(Ref: {{ ref }})</div>
        </div>
        <div class="verification-input-box">
          <ValidationObserver ref="observer">
            <div>
              <ValidationProvider
                name="รหัสยืนยันความปลอดภัย"
                :rules="'required|digits: 6'"
                v-slot="{ errors }"
              >
                <v-text-field
                  dark
                  id="input-otp"
                  type="number"
                  v-model="verify_code"
                  :hide-details="errors.length ? false : true"
                  :error-messages="errors"
                  style="max-width: 200px; margin: 0 auto"
                  class="centered-input"
                  single-line
                  placeholder="xxxxxx"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="white--text mt-9 text-center" style="font-size: 14px">
              <template v-if="showCountdowntimer">
                ส่งรหัส OTP ได้อีกครั้งใน
                <CountDownTimer
                  v-if="showCountdowntimer"
                  :startmin="timeleft"
                  @ontimeout="showCountdowntimer = false"
                >
                </CountDownTimer>
                นาที
              </template>
              <template v-else>
                <v-btn
                  text
                  class="white--text"
                  style="text-transform: underline"
                  >ส่งใหม่อีกครั้ง</v-btn
                >
              </template>
            </div>
          </ValidationObserver>
        </div>
      </template>
      <template v-else-if="$store.state.registerData.registerStatusId == '2'">
        <div class="text-center">
          <v-icon large color="white" style="font-size: 48px"
            >mdi-card-account-details-outline
          </v-icon>
          <div class="mt-5" style="font-size: 24px">ยืนยันตัวตน</div>
          <div class="white--text">รหัสหลังบัตรประชาชน</div>
        </div>
        <div class="verification-input-box">
          <ValidationObserver ref="observer">
            <div>
              <ValidationProvider
                name="รหัสหลังบัตรประชาชน"
                :rules="'required|max: 12'"
                v-slot="{ errors }"
              >
                <LaserNoThaiNationalId
                  v-model="verify_code"
                  :errors="errors"
                ></LaserNoThaiNationalId>
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </div>
      </template>
      <div class="mt-9 text-center">
        <v-btn
          dark
          :loading="loading"
          @click="confirm"
          color="#0325A0"
          width="200px"
          height="48px"
          style="font-size: 18px"
          >ยืนยัน
        </v-btn>
      </div>
      <div class="mt-2 text-center">
        <v-btn
          text
          ripple
          class="white--text"
          style="text-decoration: underline"
          @click="backToLogin"
        >
          ย้อนกลับ
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CountDownTimer from "@/components/CountDownTimer.vue";
import LaserNoThaiNationalId from "@/components/LaserNoThaiNationalId.vue";
import moment from "moment";

export default {
  components: {
    CountDownTimer,
    LaserNoThaiNationalId,
  },
  data() {
    return {
      verify_code: "",
      loading: false,
      rememberme: false,
      timeleft: 0,
      showCountdowntimer: false,
      ref: "",
    };
  },
  beforeCreate() {
    this.$store.state.bgcolor = "#efefef";
  },
  beforeDestroy() {
    this.$store.state.bgcolor = "white";
  },
  mounted() {
    if (this.$store.state.registerData.registerStatusId == 1) {
      this.ref = this.$store.state.registerData.refid;
      this.calExpireOtp();
      this.showCountdowntimer = true;
    }
  },
  computed: {
    refOtp() {
      return this.showCountdowntimer ? this.ref : "";
    },
    phoneNumber() {
      return this.$store.state.registerData.phoneNumber;
    },
  },
  methods: {
    backToLogin() {
      const query = this.$route.query;
      this.$store.state.recaptchaToken = null;
      this.verify_code = "";
      this.$router.push(
        `login?apptype=${query.apptype}&userid=${query.userid}&pageid=${query.pageid}`
      );
    },
    async confirm() {
      if (await this.$refs.observer.validate()) {
        const vm = this;
        const registerData = vm.$store.state.registerData;
        const token = vm.$store.state.recaptchaToken;
        let data = {
          registerId: registerData.registerId,
          pageId: registerData.pageId,
          userId: registerData.userId,
          app: registerData.app,
          citizenId: registerData.citizenId,
          gpf_user_id: registerData.gpf_user_id,
        };

        let url = "";
        if (this.$store.state.registerData.registerStatusId == 1) {
          Object.assign(data, { otp: vm.verify_code });
          url = `https://api-enterprise.zwiz.app/government-pension-fund/facebook/phone/validate-otp`;
        } else if (this.$store.state.registerData.registerStatusId == 2) {
          Object.assign(data, { laserCode: vm.verify_code });
          url = `https://api-enterprise.zwiz.app/government-pension-fund/facebook/phone/validate-laser-code`;
        }

        vm.loading = true;
        axios
          .post(url, data, {
            headers: {
              AUTHORIZATION: "Bearer xxx xxx",
            },
          })
          .then(async (response) => {
            vm.loading = false;
            if (response && response.data.responsecode == 200) {
              vm.verify_code = "";
              vm.showCountdowntimer = false;
              this.timeleft = 0;
              await vm.$swal
                .fire({
                  icon: "success",
                  text: "เข้าสู่ระบบสำเร็จ",
                  confirmButtonColor: "#0325A0",
                  confirmButtonText: "ปิด",
                  width: 350,
                })
                .then(async (result) => {
                  if (result.value) {
                    MessengerExtensions.requestCloseBrowser(
                      function success() {},
                      function error() {
                        window.close();
                      }
                    );
                  }
                });
            } else {
              vm.$swal.fire({
                icon: "error",
                text: "ระบบเกิดข้อผิดพลาด กรูณาลองใหม่ภายหลัง",
                confirmButtonColor: "#0325A0",
                confirmButtonText: "ปิด",
                width: 350,
              });
            }
          })
          .catch((error) => {
            vm.loading = false;
            vm.$swal.fire({
              icon: "error",
              text: "ระบบเกิดข้อผิดพลาด กรูณาลองใหม่ภายหลัง",
              confirmButtonColor: "#0325A0",
              confirmButtonText: "ปิด",
              width: 350,
            });
          });
      }
    },
    calExpireOtp() {
      let now = (now = moment(new Date()));
      let expire = this.$store.state.registerData.expiredTime;
      let duration = moment.duration(now.diff(expire));
      this.timeleft = duration.asMinutes();
    },
  },
};
</script>

<style lang="scss">
.wrapper-verfication-box {
  width: 100%;
  max-height: 500px;
  padding: 0 32px 150px;

  .verification-input-box {
    padding-top: 48px;
  }
}
#input-otp {
  font-size: 32px;
  text-align: center !important;
  color: #000;
  font-weight: 700;
  padding-bottom: 16px;
}
</style>
