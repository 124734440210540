<template>
  <span>
    {{ time }}
  </span>
</template>

<script>
import moment from "moment";
export default {
  props: {
    startmin: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      timer: null,
      date: moment(60 * this.startmin * 1000),
    };
  },

  computed: {
    time: function () {
      return this.date.format("mm:ss");
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.date = moment(this.date.subtract(1, "seconds"));

      if (this.date.format("mm:ss") === "00:00") {
        this.$emit("ontimeout");
        clearInterval(this.timer);
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
</style>